import 'mdn-polyfills/NodeList.prototype.forEach';

export default class IconPicker {
  constructor(element) {
    this.element = element;
    this.filtersInputs = this.element.querySelectorAll(".c-filter__input");
    this.iconList = this.element.querySelector(".c-download-icon__items");
    this.srColor = this.element.querySelectorAll(".c-download-icon__item__label--sr--color");
    this.srFormat = this.element.querySelectorAll(".c-download-icon__item__label--sr--format");
    this.selectedColor="black";
    this.selectedFormat="svg";
    this.downloadUrl;
    this.selectedIcon;
    this.filename;

    this.buttonsSubmit = this.element.querySelectorAll(".c-download-icon__item");

    this.change = this.change.bind(this);

  }

  mount() {
    this.filtersInputs.forEach((filterInput, index) => {
      filterInput.addEventListener('change', this.change);
    });
  }

  change(e) {
    if(e.target.getAttribute('data-name')==="color"){
      this.iconList.setAttribute("data-color", e.target.getAttribute('value'));
      this.iconList.className ="c-download-icon__items c-download-icon__items--" + e.target.getAttribute('value');
      this.iconList.querySelector(".c-download-icon__item__label--sr--color").innerText=e.target.getAttribute('value');
      this.selectedColor=e.target.getAttribute('value');
      let srColorLabel;
      if (this.selectedColor == "black") {
        srColorLabel = "noir";
      }else {
        srColorLabel = "blanc";
      }

      for (let i = 0; i < this.srColor.length; i++) {
        const element = this.srColor[i];
        element.innerText= srColorLabel;
      }

    }else {
      this.iconList.setAttribute("data-format", e.target.getAttribute('value'));
      this.iconList.querySelector(".c-download-icon__item__label--sr--format").innerText=e.target.getAttribute('value');
      this.selectedFormat=e.target.getAttribute('value');

      for (let i = 0; i < this.srFormat.length; i++) {
        const element = this.srFormat[i];
        element.innerText= this.selectedFormat
      }
    }

    this.buttonsSubmit.forEach((buttonSubmit, index) => {
      this.downloadUrl = buttonSubmit.getAttribute('data-'+this.selectedColor+'-'+this.selectedFormat);
      buttonSubmit.setAttribute("href",this.downloadUrl);
    })

  }

  unmount() {

  }
}
